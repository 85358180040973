@import "../../../styles/mixins";

#Settings {
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .language-header,
  .left-header {
    padding-right: 0.8125rem;
  }
}
.back-icon,
.goBackStyle {
  position: absolute;
  left: 0px;
  top: 50%;
  width: 35px;
  height: 0;
  // border: 1px solid rgb(135,116,225); // 修改颜色，确保在黑白模式下都能看到
  transform: rotate(180deg);
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  &:before {
    width: 1.125rem;
    height: 0.125rem;
    border-radius: 0.125rem;
    background-color: var(--color-text-secondary);
    transition: transform var(--slide-transition);
    transform: rotate(45deg) scaleX(0.75) translate(0, -0.375rem);
  }
  &:after {
    width: 1.125rem;
    height: 0.125rem;
    border-radius: 0.125rem;
    background-color: var(--color-text-secondary);
    transition: transform var(--slide-transition);
    transform: rotate(140deg) scaleX(0.75) translate(0, -0.375rem);
  }
}
.language-header {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 3rem;
  .title {
    font-size: 1.125rem;
  }
}
.settings-main-header {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.settings-more-menu {
  .bubble {
    width: auto;

    .MenuItem button {
      padding-left: 1rem !important;
      padding-right: 2rem !important;
    }
  }
}

.settings-content {
  border-top: 1px solid var(--color-borders);
  // background-color: var(--color-background-secondary);
  height: calc(100% - var(--header-height));
  position: relative;
  overflow-y: auto;
  @include overflow-y-overlay();

  &.no-border, &.two-fa, &.local-passcode, &.password-form {
    border-top: none;
  }
  &.change-password {
    padding-top: 1.375rem;
  }
  &.password-form .input-group.error label::first-letter {
    text-transform: uppercase;
  }
  &.top-line:before {
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    transform: scaleY(0.5);
    position: absolute;
    top: 0;
  }
  &.infinite-scroll {
    display: flex;
    flex-direction: column;

    & > .custom-scroll {
      flex: 1 1 auto;
      height: auto;
    }
  }

  .settings-icon-locked {
    align-self: center;
    margin-right: 0.25rem !important;
    font-size: 1rem !important;
  }

  #monkey {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .AnimatedEmoji {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}

.settings-content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem 1rem;
  text-align: center;
  background-color: var(--color-background);
  box-shadow: inset 0 -0.0625rem 0 0 var(--color-background-secondary-accent);
  margin-bottom: 0.625rem;

  &.no-border {
    margin-bottom: 0;
    box-shadow: none;
  }
  .result-logo {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1.375rem;
    justify-content: center;
    .logo {
      width: 7.5rem;
      height: 7.5rem;
      margin: 0 auto;
      background-image: url(../../../assets/fonts3/icon_change_password_result.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .logo-tips {
      margin-top: 1.25rem;
      text-align: center;
    }
  }
  .input-group {
    width: 100%;
  }
  .icon-add::before {
    color: var(--color-white);
  }
}

.settings-content-icon {
  margin-bottom: 2.5rem;

  &.opacity-transition:not(.shown) {
    display: block;
    visibility: hidden;
  }
}

.settings-main-menu-premium .PremiumIcon {
  margin-right: 2rem;
}

.settings-main-menu {
  padding: 0 0.5rem 4.25rem;
  background-color: var(--color-background);

  > .ChatExtra {
    padding: 0 0.5rem 0.3125rem;
    margin: 0 -0.5rem 0.625rem;
    border-bottom: 0.625rem solid var(--color-background-secondary);

    .ListItem.narrow {
      margin-bottom: 0.25rem;
    }
  }

  .wallet {
    margin-right: 2rem;
  }
}

.settings-item {
  background-color: var(--color-background);
  padding: 1.5rem 1.5rem 1rem;
  box-shadow: inset 0 -0.0625rem 0 0 var(--color-background-secondary-accent);
  margin-bottom: 0.625rem;

  &.no-border {
    margin-bottom: 0;
    box-shadow: none;
  }

  &-header {
    font-size: 1rem;
    color: var(--color-text-secondary);
    margin-bottom: 2rem;
    position: relative;

    &[dir="rtl"] {
      text-align: right;
    }
  }

  &-slider {
    margin-top: 2rem;
  }

  &-description {
    font-size: 0.875rem;
    color: var(--color-text-secondary);
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;

    .settings-content.two-fa &,
    .settings-content.password-form &,
    .settings-content.local-passcode & {
      font-size: 1rem;
    }

    .settings-edit-profile & {
      margin-bottom: 0;
      padding-bottom: 1.5rem;
    }

    &[dir="rtl"] {
      text-align: right;
      unicode-bidi: plaintext;
    }
  }

  &-description-larger {
    font-size: 1rem;
    line-height: 1.3125rem;
    color: var(--color-text-secondary);
    margin-top: 2rem;
    margin-bottom: 0.75rem;

    &[dir="rtl"] {
      text-align: right;
    }
  }

  .ListItem {
    margin: 0 -1rem 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    .multiline-menu-item {
      white-space: initial;
      flex-grow: 1;

      &.full-size {
        width: 100%;
        overflow: hidden;
      }

      .date {
        float: right;
        font-size: 0.75rem;
        margin-left: 1rem;
        color: var(--color-text-secondary);
      }

      .title,
      .subtitle {
        display: block;
        text-align: left;
      }

      .title {
        line-height: 1.25rem;
      }

      .subtitle {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: var(--color-text-secondary);

        & + .subtitle {
          margin-top: -0.25rem;
        }

        &.black {
          color: var(--color-text);
        }

        &.tight {
          line-height: 1.3125rem;
          margin-bottom: 0.1875rem;
        }
      }
    }

    &.destructive {
      .ListItem-button {
        color: var(--color-error);

        i {
          color: inherit;
        }
      }
    }

    &.blocked-list-item {
      margin-bottom: 0.5rem;

      .ListItem-button {
        align-items: center;
        text-align: left;
        padding: 0.5rem;
      }

      .Avatar {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
      }

      .contact-name {
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 0.25rem;
        font-weight: 500;
      }

      .contact-phone {
        font-size: 0.875rem;
        line-height: 1rem;
        color: var(--color-text-secondary);
      }
    }

    &[dir="rtl"] {
      .multiline-menu-item {
        .title,
        .subtitle {
          text-align: right;
        }

        .date {
          float: left;
          margin-left: 0;
          margin-right: 1rem;
        }
      }
    }
  }

  .RangeSlider {
    margin-bottom: 1.0625rem;
  }

  .Checkbox,
  .radio-group {
    margin: 0 -1rem 0.5rem;
  }

  .radio-group {
    .Radio:last-child {
      margin-bottom: 0;
    }
  }

  .Radio + .Radio,
  .Checkbox + .Checkbox {
    margin-top: 2rem;
  }

  &__current-value {
    margin-inline-start: auto;
    padding-inline-start: 0.5rem;
    font-size: 0.9375rem;
    color: var(--color-text-secondary);
  }
}
.settings-language-container {
    .settings-item {
      box-shadow: none;
    }
}
.settings-fab-wrapper {
  height: calc(100% - var(--header-height));
  position: relative;
  overflow: hidden;

  .settings-content {
    height: 100%;
  }

  .FloatingActionButton {
    right: 1.5rem;

    &[dir="rtl"] {
      right: auto;
      left: 1.5rem
    }
  }
}

.settings-edit-profile {
  padding: 0 1.5rem;
}

.username-link {
  color: var(--color-links);
}

.settings-quick-reaction {
  .Radio-main .label {
    display: flex;
    align-items: center;
  }

  .ReactionStaticEmoji {
    margin-inline-end: 1rem;
    width: 1.5rem;
  }
}

.SettingsDefaultReaction {
  .ReactionStaticEmoji {
    width: 1.5rem;
    height: 1.5rem;
    margin-inline-end: 2rem;
  }
}
.sharelink-text {
  white-space: normal;
  word-break: break-all;
}
.bottom-block {
  width: 100%;
  height: 4rem;
}