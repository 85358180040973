// {
//   content: '';
//   position: absolute;
//   top: -15px;
//   left: 3px;
//   height: 30px;
//   width: 30px;
//   background-color: transparent;
//   border-left: 0 solid rgb(135,116,225);
//   border-right: 2px solid rgb(135,116,225);
//   border-top: 2px solid rgb(135,116,225);
//   border-bottom: 0 solid rgb(135,116,225);
//   transform: rotate(225deg);
//   cursor: pointer;
// }
#auth-user-info-form {
  .goBackStyle {
    position: absolute;
    left: 20px;
    top: 15px;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    &:before,
    &:after {
      background-color: var(--color-icon-primary);
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
    }
  }
}
.set-language {
  position: absolute;
  right: 13px;
  top: 26px;
  color: var(--color-primary);
  cursor: pointer;
}
.divStyleCss {
  margin-bottom: 1.5rem;
  text-align: left;
  font-size: 18px;
  color: #f00;
  line-height: 1;
}

.pStyleCss {
  font-size: '18px';
  color: '#f00';
}
