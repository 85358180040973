@import "./mixins";

// Common styles for all media-type components across the app.
.media-inner {
  position: relative;

  video.full-media,
  img.full-media,
  img.thumbnail,
  canvas.thumbnail {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  video {
    background: no-repeat 50% 50%;
    background-size: contain;
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
  }

  .media-loading {
    position: absolute;
  }
}

.animated-close-icon {
  position: absolute;
  transform: rotate(-45deg);

  &,
  &::before,
  &::after {
    width: 1.125rem;
    height: 0.125rem;
    border-radius: 0.125rem;
    background-color: var(--color-text-secondary);
    transition: transform var(--slide-transition);
  }

  &::before,
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
  }

  &::before {
    transform: rotate(90deg);
  }

  &.no-transition {
    &,
    &::before,
    &::after {
      transition: none;
    }
  }

  &.state-back {
    transform: rotate(180deg);

    &::before {
      transform: rotate(45deg) scaleX(0.75) translate(0, -0.375rem);
    }

    &::after {
      transform: rotate(-45deg) scaleX(0.75) translate(0, 0.375rem);
    }
  }
}

// Used by ChatList and ContactList components
.chat-list,
.discover-list {
  width: 100%;
  height: calc(100% - 3.2rem);
  background: var(--color-background);
  padding: 0.5rem 0 0.5rem 0;

  overflow-y: hidden;
  body.is-android & {
    @include overflow-y-overlay();
  }

  .scroll-container {
    position: relative;
  }

  .ListItem.chat-item-clickable {
    margin: 0;
  }

  &.discover-content {
    // overflow-y: hidden;
    padding: 0;
  }
  .no-data {
    padding-top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 9rem;
      height: 9rem;
    }
  }
  .no-results {
    color: var(--color-text-meta);
    margin-top: 2rem;
    text-align: center;
  }
}
.chat-list {
  height: calc(100% - 5.5rem);
  overflow-y: scroll;
}
.safedistance-chat-list {
  height: calc(100% - 3.5rem);
}
.toast-tips {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.6);
  padding: .8rem .5rem;
  margin: 0 0 0 -6rem;
  border-radius: .5rem;
  position: fixed;    
  top: 50%;
  left: 50%;
  margin-left: -25%;
  margin-top: -2.5rem;
  width: 50%;
  word-break: break-all;
  text-align: center;
  z-index: 999999;
}
.position-relative {
  position: relative;
}
// Used by Avatar and ProfilePhoto components
div {
  &.color-bg-1 {
    --color-user: var(--color-user-1);
  }

  &.color-bg-2 {
    --color-user: var(--color-user-2);
  }

  &.color-bg-4 {
    --color-user: var(--color-user-4);
  }

  &.color-bg-5 {
    --color-user: var(--color-user-5);
  }

  &.color-bg-6 {
    --color-user: var(--color-user-6);
  }

  &.color-bg-7 {
    --color-user: var(--color-user-7);
  }

  &.color-bg-8 {
    --color-user: var(--color-user-8);
  }

  &.replies-bot-account,
  &.saved-messages {
    --color-user: var(--color-primary);
  }

  &.deleted-account {
    --color-user: var(--color-gray);
  }
}
